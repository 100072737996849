import React, { useState, useEffect } from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import "fullpage.js/vendors/scrolloverflow";
import "fullpage.js/vendors/easings";

// import ReactSwipe from 'react-swipe';
// import { Swiper } from 'swiper/react/swiper';
// import { SwiperSlide } from 'swiper/react/swiper-slide';
// import 'swiper/swiper.less';
// import Autoplay from 'swiper/modules/autoplay/autoplay';
// import 'swiper/modules/autoplay/autoplay.less';

import { Swiper } from "swiper/react/swiper";
import { SwiperSlide } from "swiper/react/swiper-slide";

// Import Swiper styles
import "swiper/swiper.less";
import "swiper/modules/pagination/pagination.less";
import "swiper/modules/navigation/navigation.less";

// import Swiper core and required modules
import SwiperCore, {
  Autoplay,Pagination,Navigation
} from 'swiper';

// install Swiper modules
import { animate, stagger } from "motion";


// import Lottie from 'react-lottie';
// import LottieAniData from './common/treeData/hhh.js';
import lottie from 'lottie-web';

import LottieAniData from "./common/treeData/data.json";
import * as Request from './common/request';
import * as Utils from './common/utils';
import $ from 'jquery';
import './App.less';

import IMG_LOGO from './common/images/logo.png';
import IMG_S1_RIGHT from './common/images/s1-right.svg';

import IMG_GLOBAL_EXP from './common/images/global-exp.png';
import IMG_DISTRIBUTE_EXP from './common/images/distribute-exp.png';
import IMG_GMV_EXP from './common/images/gmv-exp.png';

import IMG_GLOBAL_EXP_M from "./common/images/global-exp-mobile.png";
import IMG_DISTRIBUTE_EXP_M from './common/images/distribute-exp-mobile.png';
import IMG_GMV_EXP_M from './common/images/gmv-exp-mobile.png';

import IMG_S1_TOP_SLOGAN from './common/images/s1-top-slogan.png';
import IMG_S2_TOP_LEFT from './common/images/s2-top-left.png';
import IMG_S2_BOTTOM_RIGHT from './common/images/s2-bottom-right.png';
import IMG_S3_BOTTOM_LEFT_1 from './common/images/s3-bottom-left-1.png';
import IMG_S3_BOTTOM_LEFT_2 from './common/images/s3-bottom-left-2.png';
import IMG_S3_BOTTOM_LEFT_3 from './common/images/s3-bottom-left-3.png';
import IMG_S3_BOTTOM_LEFT_4 from './common/images/s3-bottom-left-4.png';
import IMG_S3_BOTTOM_LEFT_5 from './common/images/s3-bottom-left-5.png';
import IMG_S3_BOTTOM_LEFT_6 from './common/images/s3-bottom-left-6.png';
import IMG_S3_TOP_LEFT_1 from './common/images/s3-top-left-1.png';
import IMG_S3_TOP_LEFT_2 from './common/images/s3-top-left-2.png';
import IMG_S3_TOP_LEFT_3 from './common/images/s3-top-left-3.png';
import IMG_S3_TOP_LEFT_4 from './common/images/s3-top-left-4.png';
import IMG_S4_BOTTOM_EXTEND from './common/images/s4-bottom-extend.png';
import IMG_S4_BOTTOM_EXTEND_R from './common/images/s4-bottom-extend-red.png';
import IMG_S4_RIGHT_MIDDLE from './common/images/s4-right-middle.png';
import IMG_S_LI_PREFIX from './common/images/s-li-prefix.png';
import IMG_S_LI_PREFIX_G from './common/images/s-li-prefix-green.png';
import IMG_S5_BOTTOM_LEFT from './common/images/s5-bottom-left.png';
import IMG_S5_TOP_RIGHT from './common/images/s5-top-right.png';

SwiperCore.use([Autoplay,Pagination,Navigation]);

const utils = {
  pxToVH2: (px, base = 1080) => px / base * 100 + 'vh',
  pxToVW2: (px, base = 1920) => {
    if(window.innerWidth > 1920) {
      return px + 'px';
    }
    return px / base * 100 + 'vw';
  },
  pxToVH: (px, base = 1080) => px / 10 + 'rem',
  pxToVW: (px, base = 1920) => {
    return px / 10 + 'rem';
  },
};
if(window.isIPad) {
  utils.pxToVH = utils.pxToVH2;
  utils.pxToVW = utils.pxToVW2;
}

window.lastScrollEnd = 0;

const ANI_DURATION = 0.55;

const Header = (props) => {
  // useEffect(() => {
  //   const _ANI_DURATION = ANI_DURATION * 1.5;
  //   animate(
  //     '.moidas-navs',
  //     { transform: 'translateY(0)' },
  //     {
  //       delay: stagger(_ANI_DURATION, { start: 0.35 }),
  //       duration: _ANI_DURATION
  //     }
  //   );
  // }, [ props && props.navAni ]);
  const { rotate, funRotate } = props;
  return (
    <header className="moidas-header">
      <div className={`moidas-header-container container ani-text ani-opacity ${props.anchor == 'home' ? 'ani-s1-text': ''}`} style={{ width: window.maxWidth }}>
        <img className="moidas-logo" src={IMG_LOGO} onClick={() => window.location.replace('/')} />
        <div onMouseEnter={() => {funRotate(true);}} onMouseLeave={() => {funRotate(false);}} style={{'height': '100%', 'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'}}>
          <div className={`nav-ctrl ${rotate? 'rotate': ''}`} >
            <div className="nav-item"></div>
            <div className="nav-item"></div>
            <div className="nav-item"></div>
          </div>
          <div className={`moidas-navs ${props.navAni ? 'anim-nav': ''} ${rotate? 'show': ''}`}>
            <ul>
              <li onClick={() => {props.navHandle('about'); funRotate(false);}}>About</li>
              <li onClick={() => {props.navHandle('brands'); funRotate(false);}}>Brands</li>
              {/*<a href="javascript:void(0);" onClick={() => props.navHandle('brands')}>Partners</a>*/}
              <li onClick={() => {props.navHandle('distributor'); funRotate(false);}}>Partners</li>
              {/*<span onClick={() => props.navHandle('influencer')}>Influencer</span>*/}
              <li onClick={() => {props.navHandle('contact'); funRotate(false);}}>Contact</li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  )
};

const SectionHome = (props) => {
  const [showTree, setShowTree] = useState(false);
  const initTree = () => {
    if (!showTree) {
      lottie.loadAnimation({
        container: document.querySelector("#lottie-container"),
        animationData: LottieAniData,
        loop: false,
        autoplay: true,
        renderer: 'svg',
        rendererSettings: {
          // clearCanvas: true,
          hideOnTransparent: true,
          preserveAspectRatio: "xMidYMid meet",
          progressiveLoad: true
        },
      });
    }
  };
  useEffect(() => {
    // console.log("UseEffect");

    // setTimeout(() => {
    //   lottie.play();
    // },100)
  }, []);

  const [ isPaused, setIsPaused ] = useState(true);
  setTimeout(() => {
    setIsPaused(false);
  }, 1500);

  const moveCls = (text) => {
    if(props.anchor === 'home') {
      props.anchorsShow[0] = true;
      props.setAnchorsShow(props.anchorsShow);
    }
    return `${props.anchor === 'home' || props.anchorsShow[0] ? text :  ''}`;
  };

  return (
    <section className="moidas-section-1" style={{ overflow: 'hidden', width: window.maxWidth }}>
      <div className="moidas-section-1-container container">
        <div className="s1-left">
          <div className={`s1-title1 ani-text ani-opacity bl ${moveCls('ani-s1-text')}`}>
            {/*<img className="s1-top-slogan" src={IMG_S1_TOP_SLOGAN} alt="The Brand" />*/}
            <div>The Brand <span style={{color: 'rgba(29,45,109,0.3)'}}>Behind</span></div>
            <div><span style={{color: 'rgba(29,45,109,0.3)'}}>The Brands</span></div>
          </div>
          <p className="s1-p">
            <span className={`ani-text ani-opacity bl ${moveCls('ani-s1-text')}`}>Learn more about how we're partnering with</span>
            <span className={`ani-text ani-opacity bl ${moveCls('ani-s1-text')}`} onTransitionEnd={() => {
              initTree();
              setShowTree(true);
            }}>your favorite brands</span>
          </p>
          <div className={`s1-next ani-text ani-opacity bl ${moveCls('ani-s1-text delay5')}`} onClick={props.nextHandle}>See What We Do</div>
        </div>
        {/*<div className="s1-right"><img src={IMG_S1_RIGHT} /></div>*/}
        <div className="s1-right" >
          <div id="lottie-container" style={{width: utils.pxToVW(662)}}></div>
        </div>

      </div>
    </section>
  )
};

const ImgItem = (props) => {
  return (
    <div className={`slide-img-wrap ${props.className}`} style={props.style}>
      {/*<div className="mask" />*/}
      <div className="img-div" style={{ backgroundImage: `url(${props.img})` }} />
      {props.children}
    </div>
  );
};

const ButtonItem = (props) => {
  return (
    <div className={`slide-img-wrap ${props.className}`} style={props.style}>
      {/*<div className="mask" />*/}
      {props.children}
    </div>
  );
};

const digitalTransitionStart = (id, delay = 0) => {
  const num = $(`#${id} .title .num`);
  setTimeout(() => {
    if (num.length > 0) {

      for(let i = 0; i < num.length; i++) {
        const realN = parseInt(num[i].getAttribute('dataid'));
        const spans = num[i].querySelectorAll("i");
        let scrollHeight = 0;
        for (let j = 0; j < spans.length; j++) {
          if (parseInt(spans[j].innerText) === realN) {
            scrollHeight = spans[j].offsetTop-1;
          }
        }
        const d = num[i].querySelector(".d-inner");
        if (d) {
          d.style.webkitTransform = `translate3d(0,-${scrollHeight}px,0)`;
          d.style.webkitTransition = `all ${750 + i * 100}ms ease-in ${delay}ms`;
          if (realN === 1) {
            d.className = d.className + " thin";
          }
        }
      }
    }
  }, 500);
};


const DigitalFlopX = (props) => {
  const retuen10 = (num) => {
    return (<div className="d-inner" onTransitionEnd={(e) => {
      const p = $(e.target).parent()[0];
      p.innerText = p.getAttribute("dataid");
    }}>
      {
        new Array(num).fill(0).map((v, index) => {
          return new Array(10).fill(0).map((vj, jndex) => {
            return <i key={`iii-${jndex}`} id={`iii-${jndex}`}>{jndex}</i>
          })
        })
      }
    </div>);
  };

  const { step = 1, num } = props;
  const arr=String(num).split('');
  const digitn = parseInt(String(num).replace('.', '').replace("+", '').replace("$", ''));
  let digitl = Math.pow(10, (String(digitn)).length);
  const len=arr.length;
  return (
    <span style={{'color': `${props.color}`, 'display': 'flex', 'flexDirection': 'row'}}>{
      new Array(len).fill(0).map((v, index) => {
        if (arr[index] === '.' || arr[index] === '+' || arr[index] === "$") {
          return <span key={`num_${index}`}>{arr[index]}</span>
        } else {
          const tn = parseInt(digitn / digitl) + 1;
          digitl = digitl / 10;

          return (<span key={`num_${index}`} id={`num_${index}`} className={`num ${arr[index] == 1 ? 'thin':''}`} dataid={arr[index]}>
            {retuen10(tn)}
          </span>)
        }
      })
    }</span>
  )
};

const constructDigitFlop = (item) => {
  return (
    <div style={{'justifyContent': `flex-${item.align}`, 'alignItems': `flex-${item.align}`}}
      id={`ani-img-id${item.idx}`} className={`item item-${item.idx} ani-img-data`}>
      <div className="item-text">
        <img className="item-img" src={item.cover} />
        <div className="title-wrap">
          <span className="title" id={item.id}>
            <DigitalFlopX pid={item.id} num={item.title} mark={item.mark} color={item.bg} />
          </span>
          <div className="title-mark" style={{'color': `${item.bg}`}}>
            <span className="a b">+</span>
            <span className="aa">{item.mark}</span>
          </div>
        </div>
      </div>
      <div className="desc" style={{'color': `${item.bg}`, 'textAlign': `${item.align}`, 'justifyContent': `flex-${item.align}`}}><span>{item.desc}</span></div>
    </div>
  );
};

const SectionAbout = (props) => {
  const moveCls = (text) => {
    if(props.anchor === 'about') {
      props.anchorsShow[1] = true;
      props.setAnchorsShow(props.anchorsShow);
    }
    return `${props.anchor === 'about' || props.anchorsShow[1] ? text :  ''}`;
  };
  return (
    <section className="moidas-section-wrap m2">
      <div className="moidas-section-2" style={{ width: window.maxWidth, height: window.maxHeight }}>
        <div className="s2-left">
          <div className="s2-inner-top">
            <ImgItem className={`s2-top-left ${moveCls('show ani-s2-img1')}`} img={IMG_S2_TOP_LEFT} />
          </div>
          <div className="s2-inner-bottom">
            <ButtonItem className={`${moveCls('show ani-s2-btn1')}`}>
              {constructDigitFlop({
                align: 'end', idx: 0, id:'n2', title: '10', bg: '#7E4E9B', cover: IMG_GLOBAL_EXP_M, desc: <span>Years high-end brand <br/>management experience</span>, mark: '',
                moveCls: moveCls
              })}
            </ButtonItem>
          </div>
        </div>
        <div className="s2-right">
          <div className="s2-inner-top">
            <div className={`ani-text ani-opacity ${moveCls('ani-s2-text1 delay2')}`} style={{ display: 'flex', flexDirection: 'column' }}>
              <span className="title1">ABOUT</span>
              <span className="title2">MOIDAS</span>
            </div>
            <div>
              <p className={`ani-text ani-opacity ${moveCls('ani-s2-text1 delay2')}`} style={{ fontFamily: 'Sailec-Regular'}} id='trigger' onTransitionEnd={(e) => {
                if (e.target.id === 'trigger' && e.target.className.indexOf('ani-s2-text1') >= 0) {
                  digitalTransitionStart(`ani-img-id0`, 0);
                }
              }}>Moidas has been the global partner of many high-end skincare brands. We plan and tailor the Internet, Omni-channel Marketing, Public Relationship, O2O operation, Customer Management, Logistics Management and other comprehensive solutions for the cooperative brands, aiming to help them improve their market awareness and market performance.</p>
            </div>
          </div>
          <div className="s2-inner-bottom">
            <ImgItem className={`s2-bottom-right ${moveCls('show ani-s2-img1')}`} img={IMG_S2_BOTTOM_RIGHT} />
          </div>
        </div>
      </div>
    </section>
  )
};

const SectionBrands = (props) => {
  const moveCls = (text) => {
    if(props.anchor === 'brands') {
      props.anchorsShow[2] = true;
      props.setAnchorsShow(props.anchorsShow);
    }
    return `${props.anchor === 'brands' || props.anchorsShow[2] ? text :  ''}`;
  };
  const [hit, setHit] = useState(0);
  return (
    <section className="moidas-section-wrap m3">
      <div className="moidas-section-3" style={{ width: window.maxWidth, height: window.maxHeight }}>
        <div className="s3-left">
          <div className="s3-inner-top">
            {
              [
                {'img': IMG_S3_TOP_LEFT_1, 'txt': 'Branding Update'},
                {'img': IMG_S3_TOP_LEFT_2, 'txt': 'Business Consultant'},
                {'img': IMG_S3_TOP_LEFT_3, 'txt': 'Omni-Channel Operation'},
                {'img': IMG_S3_TOP_LEFT_4, 'txt': 'Localized Marketing & PR'},
              ].map((item, idx) => {
                return (<div key={`s3ti-${idx}`} id={`s3-top-item${idx}`} className={`ani-text ani-opacity top-inner-item delay1 ${moveCls('ani-s3-text1')}`}>
                  <img className="img" src={item.img} />
                  <p className="txt">{item.txt}</p>
                </div>);
              })
            }
          </div>
          <div className="s3-inner-bottom">
            <div className={`s3-btm slide-img-wrap ${moveCls('show s3-btm-d')}`}>
              <div className="s3-carousel">
                <Swiper
                  spaceBetween={0}
                  loop={true}
                  autoplay={{ delay: 3000, disableOnInteraction: false }}
                  // pagination={{ clickable: true }}
                  slidesPerView={2}
                  onSlideChange={setHit}
                  onSwiper={(swiper) => console.log(swiper)}
                  speed={500}
                >
                  {
                    [IMG_S3_BOTTOM_LEFT_1, IMG_S3_BOTTOM_LEFT_2, IMG_S3_BOTTOM_LEFT_3,IMG_S3_BOTTOM_LEFT_4, IMG_S3_BOTTOM_LEFT_5, IMG_S3_BOTTOM_LEFT_6].map((item, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <div className="s3-carousel-item" key={index} style={{ backgroundImage: `url(${item})` }} />
                        </SwiperSlide>
                      )
                    })
                  }
                </Swiper>
              </div>

              {/*<div className={`mask ${moveCls('hide-mask')}`}></div>*/}
            </div>
                    {/*<div className={`s3-btm`}>*/}
                    {/*  <img className={`s3-bottom-left`} src={IMG_S3_BOTTOM_LEFT_1} />*/}
                    {/*  <img className={`s3-bottom-left`} src={IMG_S3_BOTTOM_LEFT_2} />*/}
                    {/*  <img className={`s3-bottom-left`} src={IMG_S3_BOTTOM_LEFT_3} />*/}
                    {/*</div>*/}
          </div>
        </div>
        <div className="s3-right">
          <div className="s3-inner-top">
            <div className={`ani-text ani-opacity ${moveCls('ani-s3-text1')}`} style={{ display: 'flex', flexDirection: 'column' }}>
              <span className="title1">OUR</span>
              <span className="title2">BRANDS</span>
            </div>
            <div>
              <p className={`ani-text ani-opacity ${moveCls('ani-s3-text1')}`} style={{ fontFamily: 'Sailec-Regular'}} id='trigger' onTransitionEnd={(e) => {
                if (e.target.id === 'trigger' && e.target.className.indexOf('ani-s3-text1') >= 0) {
                  digitalTransitionStart(`ani-img-id1`, 600);
                }
              }}>Through the accurate understanding of consumers' motivation, shopping habits and lifestyle, we connect brands with customers tightly.</p>
            </div>
          </div>
          <div className="s3-inner-bottom">
            <ButtonItem className={`${moveCls('show ani-s3-btn1')}`}>
              {constructDigitFlop({
                align: 'start', idx: 1, id:'n2', title: '$1.2', bg: '#1D2D68', cover: IMG_GMV_EXP_M, desc: <span>Billion GMV since established</span>, mark: '',
                moveCls: moveCls
              })}
            </ButtonItem>
          </div>
        </div>
      </div>
    </section>
  )
}

const SectionDistributor = (props) => {
  const moveCls = (text) => {
    if (props.anchor === 'distributor') {
      props.anchorsShow[3] = true;
      props.setAnchorsShow(props.anchorsShow);
    }
    return `${props.anchor === 'distributor' || props.anchorsShow[3] ? text :  ''}`;
  };

  return (
    <section className="moidas-section-wrap m4">
      <div className="moidas-section-4" style={{ width: window.maxWidth, height: window.maxHeight }}>
        <div className="s4-left">
          <div className="s4-inner-top">
            <div className={`ani-text ani-opacity ${moveCls('ani-s4-text1 delay2')}`} style={{ display: 'flex', flexDirection: 'column', marginBottom: '4rem' }}>
              <span className="title1">PROGRAM</span>
              <span className="title2">INFLUENCER</span>
            </div>
            <p className={`ani-text ani-opacity ${moveCls('ani-s4-text1 delay2')}`} style={{ fontFamily: 'Sailec-Regular', marginBottom: '2rem'}}>
              Through fashion and social media, we get insight into the habits of our consumers.
            </p>
            <div className={`ani-text ani-opacity ${moveCls('ani-s4-text1 delay2')}`} style={{ fontFamily: 'Sailec-Regular'}}>
              <ul>
                <li className="s-li">
                  <img src={IMG_S_LI_PREFIX} />
                  <p>Free Trial</p>
                </li>
                <li className="s-li">
                  <img src={IMG_S_LI_PREFIX} />
                  <p>Exposure Opportunity</p>
                </li>
                <li className="s-li">
                  <img src={IMG_S_LI_PREFIX} />
                  <p>Subscribe Growth</p>
                </li>
                <li className="s-li">
                  <img src={IMG_S_LI_PREFIX} />
                  <p>Cooperation Income</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="s4-right">
          <div className={`s4-inner-top ${moveCls('show s4-inner-top-d')}`}>
            {/*<div className={`mask ${moveCls('hide-mask')}`}></div>*/}

            <img className={`s4-right-img slide-img-wrap special-scale ${moveCls('show s4-right-d')}`} height="auto" src={IMG_S4_RIGHT_MIDDLE} />
          </div>
        </div>
      </div>
      <div className="s4-extend">
        <ImgItem className={`s4-extend-img ${moveCls('show ani-s4-img1')}`} img={IMG_S4_BOTTOM_EXTEND_R} />
      </div>
    </section>
  )
};

const SectionInfluencer = (props) => {
  const moveCls = (text) => {
    if (props.anchor === 'influencer' || props.anchor === 'contact') {
      props.anchorsShow[4] = true;
      props.setAnchorsShow(props.anchorsShow);
    }
    return `${props.anchor === 'influencer' || props.anchor === 'contact' || props.anchorsShow[4]  ? text :  ''}`;
  };

  return (
    <section className="moidas-section-wrap m5">
      <div className="moidas-section-5" style={{ width: window.maxWidth, height: window.maxHeight }}>
        <div className="s5-left">
          <div className="s5-inner-top">
            <ButtonItem className={`${moveCls('show ani-s5-btn1')}`}>
              {constructDigitFlop({
                align: 'end', idx: 2, id:'n2', title: '99', bg: '#66BA9A', cover: IMG_DISTRIBUTE_EXP_M, desc: <span>BPs seclected from <br/>Omni Channel Distributors</span>, mark: '',
                moveCls: moveCls
              })}
            </ButtonItem>
          </div>
          <div className="s5-inner-bottom">
            <ImgItem className={`s5-bottom-left ${moveCls('show ani-s5-img1')}`} img={IMG_S5_BOTTOM_LEFT} />
          </div>
        </div>
        <div className="s5-right">
          <div className="s5-inner-top">
            <ImgItem className={`s5-top-right ${moveCls('show ani-s5-img1')}`} img={IMG_S5_TOP_RIGHT} />
          </div>
          <div className="s5-inner-bottom">
            <div className={`ani-text ani-opacity ${moveCls('ani-s5-text1')}`} style={{ display: 'flex', flexDirection: 'column', marginBottom: '4rem' }}>
              <span className="title1">THE</span>
              <span className="title2">DISTRIBUTOR</span>
            </div>
            <p className={`ani-text ani-opacity ${moveCls('ani-s5-text1 ')}`} style={{ fontFamily: 'Sailec-Regular', marginBottom: '2rem'}}>
              Through the characteristics of products, we establish online and offline marketingstrategies and cooperation channels.
            </p>
            <div id="trigger" className={`flex-s5-wrap ani-text ani-opacity ${moveCls('ani-s5-text1 ')}`} style={{ fontFamily: 'Sailec-Regular'}} onTransitionEnd={(e) => {
              if (e.target.id === 'trigger' && e.target.className.indexOf('ani-s5-text1') >= 0) {
                digitalTransitionStart(`ani-img-id2`, 200);
              }
            }}>
              <ul style={{marginRight: '10rem'}}>
                <li className="s-li">
                  <img src={IMG_S_LI_PREFIX_G} />
                  <p>Territorial Licensed</p>
                </li>
                <li className="s-li">
                  <img src={IMG_S_LI_PREFIX_G} />
                  <p>Product Distributor</p>
                </li>
              </ul>
              <ul>
                <li className="s-li">
                  <img src={IMG_S_LI_PREFIX_G} />
                  <p>Brand Franchise</p>
                </li>
                <li className="s-li">
                  <img src={IMG_S_LI_PREFIX_G} />
                  <p>Training & Development</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
};

const Footer = (props) => {
  const [ name, setName ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ content, setContent ] = useState('');
  const sendEmail = () => {
    const EMAIL_PATTERN = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    if(!EMAIL_PATTERN.test(email)){
      // alert('Wrong Email Format');
      Utils._alert('Wrong Email Format');
      return;
    }

    Request.post({
      url: '/email/send',
      data: {
        content,
        email,
        name
      }
    }).then(res => {
      Utils._alert('Thank you for contacting us.');
    });

    // window.location = `mailto:contact@moidas.com?subject=${name}&body=${content} ${email}`;
  };

  return (
    <footer className="moidas-section-wrap mf">
      <section className="moidas-footer" style={{ width: window.maxWidth }}>
        <div className="moidas-fleft">
            <div className="title2" style={{marginLeft: '-1px'}}>CONTACT</div>
            <div className="title1" style={{marginTop: '12px'}}>US</div>
          <div className="info">
            <span>For more business</span>
            <span>cooperation or consultation</span>
            <span>Please email at</span>
            <span style={{color: '#66BA9A'}}>Contact@moidas.com</span>
          </div>
        </div>
        <div className="moidas-fright">
          <img src={IMG_LOGO} style={{height: '36.88px'}}/>
          <div className="copyright">Copyright © 2025 Moidas All Rights Reserved</div>
          {/* <div className="title">Contact Us</div>
          <div className="form-wrap-outer">
            <div className="form-wrap"><input placeholder="Name" onChange={e => {
              setName(e.target.value);
            }} /></div>
            <div className="form-wrap"><input placeholder="Email" onChange={e => {
              setEmail(e.target.value);
            }} /></div>
          </div>
          <div className="form-wrap"><textarea onChange={e => setContent(e.target.value)} /></div>
          <div className="form-button" onClick={() => {
            sendEmail();
          }}>Submit</div> */}
        </div>
      </section>
    </footer>
  )
};

const PageModel = props => {
  // window.onload = function (){
  //   if(!!window.location.hash){
  //     window.location.replace('/');
  //   }
  // };
  const anchorsList = ['home', 'about', 'brands', 'distributor', 'influencer', 'contact'];
  const [anchorsShow, setAnchorsShow] = useState([false, false, false, false, false, false]);
  const [ rotate, setRotate ] = useState(false)
  return (
    <div className="moidas-container">
      <ReactFullpage {...{
        licenseKey: 'moidas2025',
        anchors: anchorsList,
        lockAnchors: true,
        css3: true,
        scrollingSpeed: 1550,
        autoScrolling: true,
        touchSensitivity: 15,
        easing: 'easeInOutCubic',
        easingcss3: 'ease',
        animateAnchor: false,
        // scrollOverflow: true,
        // normalScrollElements: '.section',
        render: ({ state, fullpageApi }) => {
          window.fullpageApi = fullpageApi;
          return (
            <ReactFullpage.Wrapper>
              <div className={`glass-effect ${!!rotate ? 'show': 'hide'}`} onClick={() => { setRotate(false);}} />
              <div className="fixed-header" data-name="fixed-header">
                <Header {...{
                  navAni: state.initialized,
                  funRotate:setRotate,
                  rotate: rotate,
                  anchor: !!state.destination ? state.destination.anchor : '',
                  navHandle: (target) => {
                    if(fullpageApi && fullpageApi.moveTo && typeof fullpageApi.moveTo == 'function' && target) {
                      fullpageApi.moveTo(target);
                    }
                  }
                }} />
              </div>

              <div className="section" data-name="home">
                <SectionHome {...{
                  anchor: !!state.destination ? state.destination.anchor : '',
                  anchorsShow: anchorsShow,
                  setAnchorsShow: setAnchorsShow,
                  nextHandle: () => {
                    if(fullpageApi && fullpageApi.moveTo && typeof fullpageApi.moveTo == 'function') {
                      fullpageApi.moveTo('about');
                    }
                  },
                  textAni: state.initialized
                }} />
              </div>

              <div className="section" data-name="about">
                <SectionAbout {...{
                  anchor: !!state.destination ? state.destination.anchor : '',
                  anchorsShow: anchorsShow,
                  setAnchorsShow: setAnchorsShow,
                  findMoreHandle: () => {
                    if(fullpageApi && fullpageApi.moveTo && typeof fullpageApi.moveTo == 'function') {
                      fullpageApi.moveTo('brands');
                    }
                  }
                }} />
              </div>

              <div className="section" data-name="brands">
                <SectionBrands {...{
                  anchor: !!state.destination ? state.destination.anchor : '',
                  anchorsShow: anchorsShow,
                  setAnchorsShow: setAnchorsShow
                }} />
              </div>

              <div className="section" data-name="distributor">
                <SectionDistributor {...{
                  anchor: !!state.destination ? state.destination.anchor : '',
                  anchorsShow: anchorsShow,
                  setAnchorsShow: setAnchorsShow
                }} />
              </div>

              <div className="section" data-name="influencer">
                <SectionInfluencer {...{
                  anchor: !!state.destination ? state.destination.anchor : '',
                  anchorsShow: anchorsShow,
                  setAnchorsShow: setAnchorsShow,
                  moreHandle: () => {
                    if(fullpageApi && fullpageApi.moveTo && typeof fullpageApi.moveTo == 'function') {
                      fullpageApi.moveTo('contact');
                    }
                  }
                }} />
              </div>

              <div className="section fp-auto-height" data-name="contact">
                <Footer {...{ fullpageApi }} />
              </div>
            </ReactFullpage.Wrapper>
          );
        },
        afterLoad: (section, origin, destination, direction) => {
          // if(origin && origin.anchor) {
          //   EventBus.emit(`${origin.anchor}Ani`);
          // }
          // console.log('afterLoad');
        },
        afterRender: (section, origin, destination, direction) => {
          // EventBus.emit('homeAni');
        },
        onSlideLeave: (section, origin, destination, direction) => {
          console.log('onSlideLeave');
        },
        onLeave: (origin, destination, direction) => {
          const timestamp =new Date().getTime();
          if (timestamp - window.lastScrollEnd < 2500) {
            return false;
          }
          window.lastScrollEnd = timestamp;
          // console.log('onLeave. ts: ' + timestamp1);
          if (!!window.fullpageApi) {
            // window.fullpageApi.setAllowScrolling(true);
          }

          setRotate(false);

          // console.log("origin: " + origin.anchor);
          // const timestamp1=new Date().getTime();
          // console.log("destination: " + destination.anchor + "; ts: " + timestamp1);
          // const anchor = origin.anchor;
          // const anchorIndex = anchorsList.indexOf(anchor);
          // if(direction =='down') {
          //   const nextAnchorIndex = anchorIndex + 1;
          //   const nextAnchor = anchorsList[nextAnchorIndex];
          //   // EventBus.emit(nextAnchor + 'Ani');
          // }else if(destination == 'up') {
          //   const preAnchorIndex = anchorIndex + 1;
          //   const preAnchor = anchorsList[preAnchorIndex];
          //   // EventBus.emit(preAnchor + 'Ani');
          // }
        }
      }} />
    </div>
  )
};

export default PageModel;
