import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import ReactSwipe from 'react-swipe';

import './AppMobile.less';

import lottie from 'lottie-web';
import LottieAniData from "./common/treeData/data.json";

import IMG_LOGO from "./common/images/logo.png";
import IMG_S2_BOTTOM_RIGHT from './common/images/s2-bottom-right.png';

import IMG_GLOBAL_EXP from "./common/images/global-exp-mobile.png";
import IMG_DISTRIBUTE_EXP from './common/images/distribute-exp-mobile.png';
import IMG_GMV_EXP from './common/images/gmv-exp-mobile.png';
import IMG_S3_TOP_LEFT_1 from "./common/images/s3-top-left-1.png";
import IMG_S3_TOP_LEFT_2 from "./common/images/s3-top-left-2.png";
import IMG_S3_TOP_LEFT_3 from "./common/images/s3-top-left-3.png";
import IMG_S3_TOP_LEFT_4 from "./common/images/s3-top-left-4.png";
import IMG_S3_BOTTOM_LEFT_1 from './common/images/s3-bottom-left-1.png';
import IMG_S3_BOTTOM_LEFT_2 from './common/images/s3-bottom-left-2.png';
import IMG_S3_BOTTOM_LEFT_3 from './common/images/s3-bottom-left-3.png';
import IMG_S3_BOTTOM_LEFT_4 from './common/images/s3-bottom-left-4.png';
import IMG_S3_BOTTOM_LEFT_5 from './common/images/s3-bottom-left-5.png';
import IMG_S3_BOTTOM_LEFT_6 from './common/images/s3-bottom-left-6.png';
import IMG_S5_RIGHT_MIDDLE from './common/images/s4-right-middle.png';
import IMG_S_LI_PREFIX from './common/images/s-li-prefix.png';
import IMG_S_LI_PREFIX_G from './common/images/s-li-prefix-green.png';
import IMG_S4_BOTTOM_LEFT from './common/images/s5-bottom-left.png';
import * as Request from "./common/request";
import * as Utils from "./common/utils";


function preventDefaultScroll(e) {
  e.preventDefault();
}

//禁止页面滑动
const stop = () => {
  document.body.addEventListener("touchmove", preventDefaultScroll,{ passive: false }); //禁止页面滑动
};
//取消滚动限制
const move = (e) => {
  document.body.removeEventListener("touchmove", preventDefaultScroll,{ passive: false });
};

const scroll = (e) => {
  document.body.onscroll = (e) => {
    // if (document.documentElement.scrollTop <= 0) {
    //   stop();
    // }
  };
};

const scrollToTarget = (target, callback) => {
  let t = $('#' + target).offset().top - 75;
  if (target === 'Home') {
    t = 0;
  }
  $('html,body').animate({
    scrollTop: t
  }, {
    speed: 750
  });

  callback && callback();
};

window.touchStartY = 0;

const digitalFlop = (item) => {
  return (
    <div className="digital-item" key={item.idx}>
      <div className="item-top">
        <img src={item.cover} />
        <div className="item-text-mobile">
          <div className="title-wrap">
            <span className="title" id={item.id} style={{'color': item.color}}>{item.title}</span>
            <div className="title-mark" style={{'color': item.color}}>
              <span className="b">+</span>
              <span>{item.mark}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="desc" style={{color: item.color}}><span>{item.desc}</span></div>
    </div>
  )
};

const Header = (props) => {
  const { rotate, funRotate } = props;

  return (
    <header className="mobile-header">
      <div className="nav-div">
        <div className="nav-logo">
          <img className="moidas-logo" src={IMG_LOGO} onClick={() => window.location.replace('/')} />
        </div>

        <div className={`nav-ctrl ${rotate? 'rotate': ''}`} onClick={() => {
          // console.log(rotate ^ true);
          funRotate(rotate ^ true);
          // setRotate(rotate ^ true);
        }}>
          <div className="nav-item"></div>
          <div className="nav-item"></div>
          <div className="nav-item"></div>
        </div>

        <div className={`nav-items ${rotate? 'show': ''}`}>
          <ul>
            <li onClick={() => scrollToTarget('About', () => funRotate(false))}>About</li>
            <li onClick={() => scrollToTarget('Brands', () => funRotate(false))}>Brands</li>
            <li onClick={() => scrollToTarget('Distributor', () => funRotate(false))}>Distributor</li>
            {/*<li onClick={() => scrollToTarget('Influencer', () => funRotate(false))}>Influencer</li>*/}
            <li onClick={() => scrollToTarget('Contact', () => funRotate(false))}>Contact</li>
          </ul>
        </div>
      </div>
    </header>
  )
}

const SectionHome = (props) => {
  const [firstLoadHeight, setFirstLoadHeight] = useState(window.innerHeight);
  const initTree = () => {
    if (true) {
      lottie.loadAnimation({
        container: document.querySelector("#lottie-container"),
        animationData: LottieAniData,
        loop: false,
        autoplay: true,
        renderer: 'svg',
        rendererSettings: {
          // clearCanvas: true,
          hideOnTransparent: true,
          preserveAspectRatio: "xMidYMid meet",
          progressiveLoad: true
        },
      });
    }
  };
  useEffect(() => {
    initTree();
    setFirstLoadHeight(window.innerHeight);

    // console.log(height);
  }, []);
  return (
    <section
      className="mobile-section1-container  mobile-container" id='Home' style={{'height': `${firstLoadHeight - 80}px`}}
      // onTouchStart={(e) => {
      //   // console.log('onTouchStart');
      //   window.touchStartY = e.touches[0].pageY;
      // }}
      // onTouchMove={(e) => {
      //   // console.log("OntouchMove");
      // }}
      // onTouchEnd={(e) => {
      //   // move();
      //   const endY = e.changedTouches[0].pageY - window.touchStartY;
      //   if (endY < -5) {
      //     // 向下
      //     // scrollToTarget('About', ()=>{move();});
      //   }
      // }}
    >
      {/*<div className="img1" style={{ backgroundImage: `url(${IMG_S1_RIGHT})` }} />*/}
      <div id="lottie-container" style={{'height': '45%', marginBottom: '24px', marginTop: '24px'}}></div>
      {/*<img src={IMG_S1_RIGHT} />*/}
      <div className="mobile-title1 sbold">
        <div>The Brand <span style={{color: 'rgba(29,45,109,0.3)'}}>Behind</span></div>
        <div><span style={{color: 'rgba(29,45,109,0.3)'}}>The Brands</span></div>
      </div>
      <p className="mobile-p">
        <span>Learn more about how we're partnering</span>
        <span>with your favorite brands</span>
      </p>
      <div className="mobile-s-next" onClick={() => scrollToTarget('About')}>See What We Do</div>
    </section>
  );
};
const SectionAbout = (props) => {
  return (
    <section
      className="mobile-section2-container  mobile-container" id="About"
      onTouchStart={(e) => {
        window.touchStartY = e.touches[0].pageY
      }}
      onTouchEnd={(e) => {
        const endY = e.changedTouches[0].pageY - window.touchStartY;
        // console.log(endY);
        if (endY > 5) {
          // 向下
          // scrollToTarget('Home', ()=>{stop();});
        }
      }}
    >
      <div className="img-radius img-sp-s2" style={{ backgroundImage: `url(${IMG_S2_BOTTOM_RIGHT})` }} />
      <div className="mobile-title2">
        <span>ABOUT</span>
      </div>
      <div className="mobile-title1 sbold">
        <span>MOIDAS</span>
      </div>
      <p className="mobile-p">
        <span>Moidas has been the global partner of many high-end skincare brands. We plan and tailor the Internet, Omni-channel Marketing, Public Relationship, O2O operation, Customer Management, Logistics Management and other comprehensive solutions for the cooperative brands, aiming to help them improve their market awareness and market performance.</span>
      </p>
      <div className="mobile-details" style={{"position": 'relative'}}>
        {
          digitalFlop({idx: 0, id:'n2', title: '10', cover: IMG_GLOBAL_EXP, desc: <span>Years high-end brand <br/>management experience</span>, mark: '', color: '#7E4E9B'})
        }
        {/* <img className="img-s2-addon img-radius" src={IMG_S2_BOTTOM_RIGHT} /> */}
      </div>

    </section>
  );
};
const SectionBrands = (props) => {
  const [hit, setHit] = useState(0);
  return (
    <section className="mobile-section3-container  mobile-container" id="Brands">
      <div className="mobile-title2">
        <span>OUR</span>
      </div>
      <div className="mobile-title1 sbold">
        <span>BRANDS</span>
      </div>
      <p className="mobile-p">
        <span>Through the accurate understanding of consumers' motivation, shopping habits and lifestyle, we connect brands with customers tightly.</span>
      </p>
      <div className="s3-inner">
        {
          [
            {'img': IMG_S3_TOP_LEFT_1, 'txt': 'Branding Update'},
            {'img': IMG_S3_TOP_LEFT_2, 'txt': 'Business Consultant'},
            {'img': IMG_S3_TOP_LEFT_3, 'txt': 'Omni-Channel Operation'},
            {'img': IMG_S3_TOP_LEFT_4, 'txt': 'Localized Marketing & PR'},
          ].map((item, idx) => {
            return (<div key={`sti-${idx}`} id={`s3-top-item${idx}`} className="top-inner-item">
              <img className="img" src={item.img} />
              <p className="txt">{item.txt}</p>
            </div>);
          })
        }
      </div>
      <div className="s3-carousel">
        <ReactSwipe
          swipeOptions={{
            startSlide: 0,
            continuous: true,
            auto: 2000,
            speed: 500,
            callback: setHit
          }}
        >
          {[IMG_S3_BOTTOM_LEFT_1, IMG_S3_BOTTOM_LEFT_2, IMG_S3_BOTTOM_LEFT_3,IMG_S3_BOTTOM_LEFT_4,IMG_S3_BOTTOM_LEFT_5,IMG_S3_BOTTOM_LEFT_6].map((item, index) =>  {
            return  (
              <div className="s3-carousel-item" key={index} style={{ backgroundImage: `url(${item})` }} />
            )
          })}
        </ReactSwipe>
        {/*<div className="s3-carousel-dots">
          {[IMG_S3_BOTTOM_LEFT_1, IMG_S3_BOTTOM_LEFT_2, IMG_S3_BOTTOM_LEFT_3].map((item, index) =>  {
            return  (
              <div key={`s3cd-${index}`} className={`carousel-dot ${index == hit && 'hit' || ''}`} id={`s3-c-dot-${index}`}></div>
            )
          })}
        </div> */}
      </div>
      <div className="mobile-details">
        {
          digitalFlop({idx: 1, id:'n1', title: '$1.2', cover: IMG_GMV_EXP, desc: <span>Billion GMV since established</span>, mark: '', color: '#1D2D68'})
        }
      </div>
    </section>
  );
};
const SectionDistributor = (props) => {
  return (
    <section className="mobile-section4-container  mobile-container" id="Distributor">
      <div className="mobile-details" style={{'paddingTop': 0, 'position': 'relative'}}>
        {
          digitalFlop({idx: 3, id:'n0', title: '99', cover: IMG_DISTRIBUTE_EXP, desc: <span>BPs selected from <br/>omni channel distributors</span>, mark: '', color: 'rgb(86,174,136)'})
        }
        {/* <img className="img-s4-addon img-radius" src={IMG_S4_TOP_RIGHT} /> */}
      </div>
      <div className="mobile-title2">
        <span>THE</span>
      </div>
      <div className="mobile-title1 sbold">
        <span>DISTRIBUTOR</span>
      </div>
      <p className="mobile-p">
        <span>Through the characteristics of products, we establish online and offline marketingstrategies and cooperation channels.</span>
      </p>
      <div className="mobile-p">
        <ul style={{'marginTop': 0}}>
          <li className="ss-li">
            <img src={IMG_S_LI_PREFIX_G} />
            <p>Territorial Licensed</p>
          </li>
          <li className="ss-li">
            <img src={IMG_S_LI_PREFIX_G} />
            <p>Product Distributor</p>
          </li>
          <li className="ss-li">
            <img src={IMG_S_LI_PREFIX_G} />
            <p>Brand Franchise</p>
          </li>
          <li className="ss-li">
            <img src={IMG_S_LI_PREFIX_G} />
            <p>Training & Development</p>
          </li>
        </ul>
      </div>
      <div className="mobile-img-wrap">
        <img className="img-radius" src={IMG_S4_BOTTOM_LEFT} />
      </div>
    </section>
  );
};
const SectionInfluencer = (props) => {
  return (
    <section className="mobile-section5-container  mobile-container" id="Influencer">
      <div className="mobile-title2">
        <span>PROGRAM</span>
      </div>
      <div className="mobile-title1 sbold">
        <span>INFLUENCER</span>
      </div>
      <p className="mobile-p">
        <span>Through fashion and social media, we get insight into the habits of our consumers.</span>
      </p>
      <div className="mobile-p">
        <ul style={{'marginTop': 0}}>
          <li className="ss-li">
            <img src={IMG_S_LI_PREFIX} />
            <p>Free Trial</p>
          </li>
          <li className="ss-li">
            <img src={IMG_S_LI_PREFIX} />
            <p>Exposure Opportunity</p>
          </li>
          <li className="ss-li">
            <img src={IMG_S_LI_PREFIX} />
            <p>Subscribe Growth</p>
          </li>
          <li className="ss-li">
            <img src={IMG_S_LI_PREFIX} />
            <p>Cooperation Income</p>
          </li>
        </ul>
      </div>
      <div className="mobile-img-wrap"><img src={IMG_S5_RIGHT_MIDDLE} /></div>
    </section>
  );
};

const Footer = () => {
  const [ name, setName ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ content, setContent ] = useState('');
  const sendEmail = () => {
    const EMAIL_PATTERN = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    if(!EMAIL_PATTERN.test(email)){
      // alert('Wrong Email Format');
      Utils._alert('Wrong Email Format');
      return;
    }

    Request.post({
      url: '/email/send',
      data: {
        content,
        email,
        name
      }
    }).then(res => {
      Utils._alert('Thank you for contacting us.');
      setName('');
      setEmail('');
      setContent('');
    });
    // window.location = `mailto:contact@moidas.com?subject=${name}&body=${content} ${email}`;
  };

  return (
    <footer className="mobile-footer" id="Contact">
      <div className="title">CONTACT</div>
      <div className="mobile-form-wrap-outer">
        <div className="form-wrap"><label>Name:</label><input value={name} onChange={e => {
          setName(e.target.value);
        }} /></div>
        <div className="form-wrap"><label>Email:</label><input value={email} onChange={e => {
          setEmail(e.target.value);
        }} /></div>
      </div>
      <div className="form-wrap"><textarea value={content} onChange={e => setContent(e.target.value)} /></div>
      <div className="form-button" onClick={() => {
        sendEmail();
      }}>Submit</div>
      <div className="mobile-copyright">Copyright © 2025 Moidas All Rights Reserved. </div>
    </footer>
  )
}

const PageModel = props => {
  const [ rotate, setRotate ] = useState(false);
  // useEffect(()=> {window.firstLoadInnerHeight = window.innerHeight}, []);
  return (
    <div className="moidas-container-mobile" >
      <Header funRotate={setRotate} rotate={rotate} />
      {/*<Container />*/}
      <div className="mobile-header-pl"></div>
      <div className={`section-wrap`}>
        {!!rotate && (<div className={`glass-effect-m`} onClick={() => { setRotate(false); }} />)}
        <div className="merged-bg">
          <SectionHome funRotate={setRotate} rotate={rotate} />
          <SectionAbout funRotate={setRotate} rotate={rotate} />
        </div>
        <SectionBrands />
        <SectionInfluencer />
        <SectionDistributor />
        <Footer />
      </div>
    </div>
  )
};

export default PageModel;
